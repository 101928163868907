<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="row">
        <div class="col d-flex">
          <h3 class="my-auto">Categorías Restaurantes</h3>
        </div>
        <div class="col-auto">
          <v-btn
            color="secondary"
            class="text--primary text-none elevation-0 px-5"
            @click="openCategoryModal()"
            dark
          >
            Nuevo
          </v-btn>
        </div>
      </div>
    </div>
    <div class="col-12">
      <data-table
        ref="categoriesTable"
        :url="url"
        :params="params"
        :headers="headersCategories"
        :mobile="true"
        :withoutPagination="true"
      >
        <template v-slot:item.arrows="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.last"
                large
                color="black"
                class="ml-5"
                center
                v-bind="attrs"
                v-on="on"
                @click="onChangeOrderCategory(item, 0)"
              >
                fa fa-angle-down
              </v-icon>
              <v-icon large class="ml-10 pl-1" v-else></v-icon>
            </template>
            <span>Subir Posición</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.first"
                large
                color="black"
                class="ml-5"
                center
                v-bind="attrs"
                v-on="on"
                @click="onChangeOrderCategory(item, 1)"
              >
                fa fa-angle-up
              </v-icon>
              <v-icon large class="ml-5" v-else></v-icon>
            </template>
            <span>Bajar Posición</span>
          </v-tooltip>
        </template>

        <template v-slot:item.isActive="{ item }">
          <v-switch
            v-model="item.isActive"
            dense
            hide-details
            class="ma-0"
            @click="onChangeStatusCategory(item)"
          ></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="openCategoryModal(item)"
                small
                color="primary"
              >
                <v-icon center dark small>
                  fa fa-edit
                </v-icon>
              </v-btn>
            </template>
            <span>Editar Restaurante</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="onDeleteCategory(item)"
                small
                color="primary"
              >
                <v-icon center dark small>
                  fa fa-trash
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar Actividad</span>
          </v-tooltip>
        </template>
      </data-table>

      <restaurant-category-modal
        ref="categoryModal"
        :fairId="fairId"
        :url="url"
        @onClose="onClose"
      />

      <confirm-dialog
        :title="'Confirmación'"
        :description="
          `¿Deseas ${
            Boolean(selectedCategory.isActive) ? 'activar' : 'desactivar'
          } la actividad?`
        "
        @onClose="closeChangeStatusCategoryModal()"
        @onConfirm="changeStatusCategory()"
        :is-active="isConfirmChangeStatusCategory"
      />

      <confirm-dialog
        :title="'Confirmación'"
        :description="`¿Deseas eliminar la actividad ${selectedCategory.name}?`"
        @onClose="closeDeleteCategoryModal()"
        @onConfirm="deleteCategory()"
        :is-active="isConfirmDeleteCategory"
      />
    </div>
  </div>
</template>

<script>

import headersCategories from '../data/HeadersCategories'
import DataTable from '@/components/data-table/DataTable'
import RestaurantCategoryModal from './RestaurantCategoryModal.vue'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { RESTAURANT_CATEGORIES_URL } from '@/constants/ServicesMobileConstants'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import eventHub from '@/eventhub'

export default {
  name: 'RestaurantCategories',
  components: {
    ConfirmDialog,
    DataTable,
    RestaurantCategoryModal
  },
  props: ['fairId'],
  data () {
    return {
      url: RESTAURANT_CATEGORIES_URL,
      selectedCategory: {},
      headersCategories,
      params: {
        fairId: this.fairId
      },
      isConfirmChangeStatusCategory: false,
      isConfirmDeleteCategory: false
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    onClose () {
      this.$refs.categoriesTable.getDataFromApi()
      eventHub.$emit('update-restaurant-categories')
    },
    openCategoryModal (item) {
      this.$refs.categoryModal.open(item)
    },
    onChangeStatusCategory (item) {
      this.selectedCategory = item
      this.isConfirmChangeStatusCategory = true
    },
    onChangeOrderCategory (item, toMove) {
      this.selectedCategory = item
      this.selectedCategory.toMove = toMove
      this.changeOrderCategory()
    },
    onDeleteCategory (item) {
      this.selectedCategory = item
      this.isConfirmDeleteCategory = true
    },
    changeStatusCategory () {
      this.isConfirmChangeStatusCategory = false
      this.putMobile(
        this.url + '/' + this.selectedCategory.id + '/change-status',
        this.selectedCategory
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.categoriesTable.getDataFromApi()
        eventHub.$emit('update-restaurant-categories')
      })
    },
    deleteCategory () {
      this.isConfirmDeleteCategory = false
      this.deleteMobile(this.url, this.selectedCategory.id).then(
        res => {
          this.showSuccess(res.data.success)
          this.$refs.categoriesTable.getDataFromApi()
          eventHub.$emit('update-restaurant-categories')
        }
      )
    },
    changeOrderCategory () {
      this.getMobile(
        this.url + '/' +
        this.selectedCategory.id +
        '/change-order/' +
        this.selectedCategory.toMove
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.categoriesTable.getDataFromApi()
        eventHub.$emit('update-restaurant-categories')
      })
    },
    closeChangeStatusCategoryModal () {
      this.isConfirmChangeStatusCategory = false
      this.selectedCategory.isActive = !this.selectedCategory.isActive
    },
    closeDeleteCategoryModal () {
      this.isConfirmDeleteCategory = false
    }
  },
  mixins: [crudServiceMixin, crudServiceMobileMixin, notificationMixin]
}

</script>
