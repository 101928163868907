<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if='fair'
        :title='fair.name'
        type='banner--admin'
        :options='{ padding: "30px" }'
      >
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Configuración Restaurantes</h2>
          </div>
        </div>
        <restaurant-categories :fairId="params.fairId"></restaurant-categories>
        <restaurant-list :fairId="params.fairId"></restaurant-list>
      </div>
    </template>
  </fair-layout>
</template>

<script>

import FairLayout from '@/layouts/FairLayout'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import sessionMixin from '@/mixins/sessionMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'

import Banner from '@/components/banner/Banner'
import RestaurantCategories from './components/RestaurantCategories.vue'
import RestaurantList from './components/RestaurantList.vue'
import loaderMixin from '../../../mixins/loaderMixin'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    Banner,
    FairLayout,
    RestaurantCategories,
    RestaurantList
  },
  data () {
    return {
      params: {
        fairId: +(this.$route.params.fairId)
      },
      fair: null
    }
  },
  methods: {
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.params.fairId)
      this.fair = response.data
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    notificationMixin,
    crudServiceMixin,
    loaderMixin
  ],
  name: 'Restaurants'
}

</script>
