const headersCategories = [
  {
    text: 'Orden',
    value: 'order',
    sortable: false
  },
  {
    text: 'Nombre Restaurante',
    value: 'name',
    sortable: false
  },
  {
    text: 'Ubicación',
    value: 'title',
    sortable: false
  },
  {
    text: 'Descripción',
    value: 'description',
    sortable: false
  },
  {
    text: 'Categoría',
    value: 'category',
    sortable: false
  },
  {
    text: '',
    value: 'arrows',
    sortable: false
  },
  {
    text: '',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '15%'
  }
]
export default headersCategories
