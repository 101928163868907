<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="row">
        <div class="col d-flex">
          <h3 class="my-auto">Restaurantes</h3>
        </div>
        <div class="col-auto">
          <v-btn
            color="secondary"
            class="text--primary text-none elevation-0 px-5"
            @click="openRestaurantModal()"
            dark
          >
            Nuevo
          </v-btn>
        </div>
      </div>
    </div>
    <div class="col-12">
      <data-table
        ref="restaurantsTable"
        :url="url"
        :params="params"
        :headers="headersRestaurants"
        :mobile="true"
        :withoutPagination="true"
      >
        <template v-slot:item.category="{ item }">
          <span v-text="item.category ? item.category.name : ''"></span>
        </template>
        <template v-slot:item.arrows="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.last"
                large
                color="black"
                class="ml-5"
                center
                v-bind="attrs"
                v-on="on"
                @click="onChangeOrderRestaurant(item, 0)"
              >
                fa fa-angle-down
              </v-icon>
              <v-icon large class="ml-10 pl-1" v-else></v-icon>
            </template>
            <span>Subir Posición</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.first"
                large
                color="black"
                class="ml-5"
                center
                v-bind="attrs"
                v-on="on"
                @click="onChangeOrderRestaurant(item, 1)"
              >
                fa fa-angle-up
              </v-icon>
              <v-icon large class="ml-5" v-else></v-icon>
            </template>
            <span>Bajar Posición</span>
          </v-tooltip>
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-switch
            v-model="item.isActive"
            dense
            hide-details
            class="ma-0"
            @click="onChangeStatusRestaurant(item)"
          ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="openRestaurantModal(item)"
                small
                color="primary"
              >
                <v-icon center dark small>
                  fa fa-edit
                </v-icon>
              </v-btn>
            </template>
            <span>Editar Restaurante</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="onDeleteRestaurant(item)"
                small
                color="primary"
              >
                <v-icon center dark small>
                  fa fa-trash
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar Actividad</span>
          </v-tooltip>
        </template>
      </data-table>

      <restaurant-modal
        ref="restaurantModal"
        :fairId="fairId"
        :url="url"
        :categories="categories"
        @onClose="onClose"
      />

      <confirm-dialog
        :title="'Confirmación'"
        :description="
          `¿Deseas ${
            Boolean(selectedRestaurant.isActive) ? 'activar' : 'desactivar'
          } la actividad?`
        "
        @onClose="closeChangeStatusRestaurantModal()"
        @onConfirm="changeStatusRestaurant()"
        :is-active="isConfirmChangeStatusRestaurant"
      />

      <confirm-dialog
        :title="'Confirmación'"
        :description="
          `¿Deseas eliminar la actividad ${selectedRestaurant.name}?`
        "
        @onClose="closeDeleteRestaurantModal()"
        @onConfirm="deleteRestaurant()"
        :is-active="isConfirmDeleteRestaurant"
      />
    </div>
  </div>
</template>

<script>
import headersRestaurants from '../data/HeadersRestaurants'
import DataTable from '@/components/data-table/DataTable'
import RestaurantModal from './RestaurantModal.vue'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { RESTAURANTS_URL } from '@/constants/ServicesMobileConstants'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  name: 'RestaurantList',
  components: {
    ConfirmDialog,
    DataTable,
    RestaurantModal
  },
  props: ['fairId', 'categories'],
  data () {
    return {
      url: RESTAURANTS_URL,
      selectedRestaurant: {},
      headersRestaurants,
      params: {
        fairId: this.fairId
      },
      isConfirmChangeStatusRestaurant: false,
      isConfirmDeleteRestaurant: false
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    onClose () {
      this.$refs.restaurantsTable.getDataFromApi()
    },
    openRestaurantModal (item) {
      this.$refs.restaurantModal.open(item)
    },
    onChangeStatusRestaurant (item) {
      this.selectedRestaurant = item
      this.isConfirmChangeStatusRestaurant = true
    },
    onChangeOrderRestaurant (item, toMove) {
      this.selectedRestaurant = item
      this.selectedRestaurant.toMove = toMove
      this.changeOrderRestaurant()
    },
    onDeleteRestaurant (item) {
      this.selectedRestaurant = item
      this.isConfirmDeleteRestaurant = true
    },
    changeStatusRestaurant () {
      this.isConfirmChangeStatusRestaurant = false
      this.putMobile(
        this.url + '/' + this.selectedRestaurant.id + '/change-status',
        this.selectedRestaurant
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.restaurantsTable.getDataFromApi()
      })
    },
    deleteRestaurant () {
      this.isConfirmDeleteRestaurant = false
      this.deleteMobile(this.url, this.selectedRestaurant.id).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.restaurantsTable.getDataFromApi()
      })
    },
    changeOrderRestaurant () {
      this.getMobile(
        this.url +
          '/' +
          this.selectedRestaurant.id +
          '/change-order/' +
          this.selectedRestaurant.toMove
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.restaurantsTable.getDataFromApi()
      })
    },
    closeChangeStatusRestaurantModal () {
      this.isConfirmChangeStatusRestaurant = false
      this.selectedRestaurant.isActive = !this.selectedRestaurant.isActive
    },
    closeDeleteRestaurantModal () {
      this.isConfirmDeleteRestaurant = false
    }
  },
  mixins: [crudServiceMixin, crudServiceMobileMixin, notificationMixin]
}
</script>
