<template>
  <div class="row">
    <v-dialog v-model="isActive" max-width="1000px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ model.id ? "Edición" : "Creación" }} de Restaurante
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row mt-3">
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombre"
                  vid="name"
                  rules="required|max:150"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Nombre"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Categoría"
                  vid="restaurantCategoryId"
                  rules="required"
                >
                  <v-select
                    :items="categories"
                    v-model="model.restaurantCategoryId"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Categoría"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Descripción"
                  vid="description"
                  rules="required|max:500"
                >
                  <v-textarea
                    v-model="model.description"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Descripción"
                    required
                    counter="500"
                    height="100"
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Ubicación"
                  vid="title"
                  rules="required|max:150"
                >
                  <v-text-field
                    v-model="model.title"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Ubicación"
                    required
                  />
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveRestaurant">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import eventHub from '@/eventhub'
import {
  RESTAURANTS_URL,
  RESTAURANT_CATEGORIES_URL
} from '@/constants/ServicesMobileConstants'

export default {
  name: 'RestaurantCategoryModal',
  props: {
    fairId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        id: null,
        name: null,
        title: null,
        order: this.order,
        restaurantCategoryId: null,
        description: null
      },
      categories: []
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        id: null,
        name: null,
        order: null,
        title: null,
        description: null
      }
    },
    open (restaurant) {
      this.isActive = true
      if (restaurant) {
        this.model = { ...restaurant }
      }
    },
    saveRestaurant () {
      this.model.fairId = this.fairId
      this.$refs.form.validate().then(result => {
        if (result) {
          if (this.model.id) {
            this.putMobile(
              RESTAURANTS_URL + '/' + this.model.id,
              this.model
            ).then(res => {
              this.close()
              this.showSuccess(res.data.success)
              this.$emit('onClose')
            })
          } else {
            this.postMobile(RESTAURANTS_URL, this.model).then(res => {
              this.close()
              this.showSuccess(res.data.success)
              this.$emit('onClose')
            })
          }
        }
      })
    },
    getCategories () {
      this.getMobile(RESTAURANT_CATEGORIES_URL, {
        params: { isActive: true, fairId: this.fairId }
      }).then(res => {
        this.categories = res.data
      })
    }
  },
  created () {
    this.getCategories()
    eventHub.$on('update-restaurant-categories', () => {
      this.getCategories()
    })
  },
  mixins: [crudServiceMobileMixin, notificationMixin, loaderMixin]
}
</script>
