const headersCategories = [
  {
    text: 'Orden',
    value: 'order',
    sortable: false
  },
  {
    text: 'Nombre Categoría',
    value: 'name',
    sortable: false
  },
  {
    text: '',
    value: 'arrows',
    sortable: false
  },
  {
    text: '',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '15%'
  }
]
export default headersCategories
